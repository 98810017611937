
div.comment * {
    user-select: text;
}
div.comment.combined {
    margin-top: 0px;
}

div.comment {
    display: flex;
    flex-direction: column;
}

div.commentInfo {
    display: flex;
    flex-direction: row;
}

div.avatarContainer {
    width: 40px;
    height: 40px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

img.commentAvatar {
    margin: 0;
    border-radius: 5px;
    border: 1px solid rgba(100, 100, 100, 0.3);
    box-shadow: 1px 1px 5px 0px #000000;
    cursor: pointer;
}

div.commentInfoContent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 5px 0;
    margin-left: 10px;
}

div.commentAuthor {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    color: black;
}

div.commentMetadata {
    display: inline-block;
    margin-left: 0.5em;
    color: rgba(0,0,0,.5);
    font-size: .875em;
}

div.commentContent {
    margin-left: 60px;
    padding-right: 20px;
    min-width: 200px;
}

p.commentLine {
    margin: 0;
    margin-bottom: 5px;
    color: black;
}

div.commentContent > * {
    word-wrap: break-word;
}


.tooltip > * {
    display: block;
}

.tooltipContent {
    position: absolute;
    animation: fadein 0.7s;
}

span.tooltipContent {
    padding: 5px;
    white-space: nowrap;
    background-color: rgba(200, 200, 200);
    box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    font-weight: 600;
}

div.imageUploadDiv {
    width: 30vw;
    height: 30vw;
    max-width: 200px;
    max-height: 200px;
    min-width: 150px;
    min-height: 150px;
    border-radius: 10px;
    margin: 20px;
    padding: 10px;
    margin-left: 20px;
    border: 3px dashed rgb(120, 120, 120);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    white-space: normal;
    text-align: center;
}

label.fileInputLabel {
    padding: 10px 20px;
    background-color: rgb(50, 150, 250);
    color: white;
    border-radius: 8px;
    cursor: pointer;
}

input.fileUploadInput {
    display: none;
}

div.errorDiv {
    border: 2px solid rgb(200, 50, 50);
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
    color: rgb(150, 20, 20);
    background-color: rgba(200, 50, 50, 0.2);
}

div.uploadedImageDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
}

div.imagePreviewDiv {
    width: 250px;
    height: 250px;
    border-radius: 15px;
    border: 1px solid rgb(100, 100, 100);
    overflow: hidden;
}

div.uploadedImageButtonDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
}

div.uploadedImageButtonDiv > button {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid rgb(100, 100, 100);
    color: black;
}
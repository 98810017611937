div.loadingContainer {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}

div.loadingOuterDiv {
    width: 70vw;
    height: 70vw;
    max-width: 500px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.loadingDiv {
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(6px);
    width: 90%;
    height: 80%;
    border-style: solid;
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left-width: 1px;
    border-right-width: 1px; 
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 10s infinite linear;
    box-shadow: 0 0 15px 0 black;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        /* width: 95%; */
    }
    50% {
        transform: rotate(180deg);
        /* width: 75%; */
    }
    100% {
        transform: rotate(360deg);
        /* width: 95%; */
    }
}

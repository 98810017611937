div.mainHeader {
    z-index: 5; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(34,36,38,.15); 
}

div.headerMenuLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

div.headerMenuRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7.5px 15px;
}

h1.titleString {
    margin: 0 !important;
    font-size: 2.5rem;
    font-family: 'Montserrat';
    font-style: oblique;
    font-weight: 300;
    letter-spacing: -3px;
    background: linear-gradient(rgb(10, 50, 90), rgb(100, 100, 180));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

span#bolder {
    font-weight: 900 !important;
}

i.sidebarIcon {
    margin-top: 3px;
    margin-right: 10px;
}

div.headerDropdownMenu {
    position: absolute;
    right: 0;
    top: 50px;
    width: 200px;
    transition: all 0.5s ease;
    height: auto;
}

div.headerDropdownMenu:not(*) {
    height: 0px;
}

div.headerDropdownMenuOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

div.headerDropdownMenuContent {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    z-index: 15;
}

div.headerDropdownMenuContent > * {
    z-index: 16;
}

div.dropdownMenuItem {
    border: 1px solid rgb(200, 200, 200) !important;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

div.dropdownMenuItem:not(:last-child) {
    margin-bottom: 3px;
}

div.dropdownMenuItem > input {
    margin: 0 10px 0 5px !important;
    padding: 0 !important;
    width: 100%;
    flex: 2;
}

div.dropdownMenuItem > i {
    width: 100%;
    flex: 1;
    transition: background-color 0.3s ease;
}

div.dropdownMenuItem > i:hover {
    background-color: rgb(200, 200, 200);
}

div.menuItemButton {
    transition: background-color 0.3s ease;
}

div.menuItemButton:hover {
    background-color: rgb(200, 200, 200);
}


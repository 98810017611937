
div.settingsContainerDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.settingsBody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

div.settingsMenuHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
}

div.settingsMenuHeaderItem {
    flex: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: rgba(200, 200, 200, 0.3);
    border-bottom: 2px solid rgba(100, 100, 100, 0.5);
    transition: all 0.3s ease;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 1px 5px 0px rgb(50, 50, 50);
}

div.settingsMenuHeaderItem:not(:last-child) {
    border-right: 1px solid rgba(100, 100, 100, 0.5); 
}

div.settingsMenuHeaderItem:hover {
    background-color: rgba(150, 150, 150, 0.3);
}

div.settingsMenuHeaderItem.active {
    background-color: rgba(100, 100, 100, 0.5);
    border-bottom: 2px solid rgb(50, 50, 50);
}



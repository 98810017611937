div#mainBody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

div#sidebar {
    flex: 0 1;
    position: relative;
    display: flex;
    flex-direction: row;
    box-shadow: 3px 0px 8px 1px rgb(0 0 0 / 45%);    
}

div#sidebar:not(.resizing) {
    transition: flex-basis 0.5s ease;
}

div#sidebarContent {
    flex: 1 1;
    width: 100%;
    height: 100%;
    position: absolute;
    right: var(--handleWidth);
    width: calc(100% - var(--handleWidth));
    min-width: calc(var(--sidebarWidth));
}

div#sidebarHandle {
    position: absolute;
    right: 0;
    height: 100%;
    width: var(--handleWidth);
    background-color: rgba(255, 255, 255, 0.3);
    user-select: none;
    border-top-right-radius: var(--handleWidth);
    border-bottom-right-radius: var(--handleWidth);
    /* box-shadow: 0 0 20px rgb(34 36 38 / 70%); */
}

div#mainContent {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}


 
.textAreaInputContainer {
    margin: 0;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: border-color .1s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    padding-right: 20px !important;
}

.textAreaInputContainer:focus-within {
    border-color: #85b7d9;
    border-radius: 0.28571429rem;
    background: #fff;
    box-shadow: 0 0 0 0 rgb(34 36 38 / 35%) inset;
}

.textAreaInput {
    resize: none !important;
}

div.textAreaInput {
    margin: 0;
    padding: 0.78571429em 1em;
    outline: 0;
    max-height: 300px;
    overflow-y: auto;
    color: rgba(0,0,0,.87);
    transition: color .1s ease;
    font-size: 1em;
}

div.textAreaInput:focus {
    color: rgba(0,0,0,.95);
}

.richTextMenu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    padding-bottom: 0px;
    transition: all 0.4s;
    opacity: 1;
    flex-basis: 30px;
}

.richTextMenu.closed {
    flex-basis: 0px;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important;
}

.richTextMenu.closed > * {
    display: none;
}

div.iconContainer {
    width: 30px;
    box-shadow: 0 0 0 0.1em rgb(0 0 0 / 10%) inset;  
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center; 
    transition: all 0.3s;
    overflow: hidden;
}

div.iconContainer:hover {
    background-color: rgba(50, 150, 200, 0.3);
}

div.iconContainer[active] {
    background-color: rgba(0, 170, 255, 0.624);
}

div.iconContainer > i {
    font-size: 1.2rem;
}

div.iconContainer > i.fa-font:not(.inverted) {
    border-bottom: 3px solid black;
    padding: 0 3px;
}

div.iconContainer > i.fa-font.inverted {
    padding: 3px;
    background-color: black;
    color:white;
}

div.colorPickerDiv {
    position: relative;
}

div.sketch-picker {
    position: absolute;
    bottom: 28px;
    left: 0;
    z-index: 50;
}

div.dropdownContainer {
    width: 100px;
    box-shadow: 0 0 0 0.1em rgb(0 0 0 / 10%); 
}

div.iconContainer, div.dropdownContainer {
    margin: 0 5px 5px 0;
    height: 30px;
    border-radius: 5px;
}

div.dropdownContainer > select {
    height: 100% !important;
    padding: 0px !important;
    font-size: 1.2rem;
}

div.imageElementContainer {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}

div.rightMenuIconDiv {
    position: absolute;
    z-index: 20;
    right: -1px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: -1px 0px 2px 1px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

div.rightMenuIconDiv > i {
    font-size: 24px;
    transition: all 0.5s;
}

div.rightMenuIconDiv > i:hover {
    transform: scale(1.2);
}

div.rightMenuIconDiv > i.open {
    transform: rotate(180deg);
}

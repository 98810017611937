
div.sidebarMenu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

div.sidebarMenuContent {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    flex: 1;
    animation: fadein 0.7s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

div.sidebarMenuContent::-webkit-scrollbar {
    width: 5px;
}

div.sidebarMenuContent::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .30);
}

div.sidebarMenuContent::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

div.sidebarPaddingDiv {
    padding: 10px;
}

div.sidebarMenuButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


div.sidebarMenuButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 15px 10px;
    transition: all 0.3s ease;
    width: 100%;
    border: 1px solid rgba(100, 100, 100, 0.7);
    border-bottom: none;
    border-right: none;
    cursor: pointer;
}

div.sidebarMenuButton:hover {
    background-color: rgba(150, 150, 150, 0.7);
}

div.backDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: left;
    padding: 10px;
}

i.backButtonIcon {
    transition: all 0.3s ease;
}

i.backButtonIcon:hover {
    color: rgb(50, 50, 50);
    background-color: rgba(100, 100, 100, 0.5);
}

i.backButtonIcon:active {
    transition: none;
    color: white;
}

div.noRoomsSidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%
}

.noRoomsText {
    max-width: 220px;
    text-align: center;
    margin-bottom: 20px !important;
}
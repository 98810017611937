

div.avatarSelectMenu {
    width: 100%;
    height: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h4.avatarDivider {
    width: 100%;
    border-bottom: 1px solid black;
    white-space: nowrap;
}

div.defaultAvatarSelect {
    padding: 20px;
    display: grid;
}

div.avatarUrlField {
    width: 100%;
    padding: 20px;
}

div.avatarPreviewDiv {
    width: fit-content;
}

img.avatarPreview {
    margin: 3px;
    border-radius: 5px;
    border: 1px solid rgb(150, 150, 150);
    transition: all 0.3s ease;
}

img.avatarPreview:hover {
    box-shadow: 1px 1px 5px 0px rgb(50, 50, 50);
    transform: translateY(-5px);
}
 

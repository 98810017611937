div.userProfileFormContainer {
    width: 100%;
    height: 100%;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

div.avatarInputContainer {
    margin-right: 40px;
    margin-bottom: 40px;
}

div.formFieldGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}

img.avatarImage {
    border-radius: 10px;
    border: 1px solid rgb(100, 100, 100);
}

div.avatarContainerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

div.avatarContainerDiv > button {
    margin: 0;
    margin-top: 5px;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid rgb(150, 150, 150);    
}


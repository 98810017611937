
@keyframes fadein {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

* {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0 !important;
}

html, body, div#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  user-select: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
}

div#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://storage.googleapis.com/bienchats.appspot.com/images%2F20190711_210421.jpg');
  background-size: cover;  
}

div.outerContainer {
  width: 90%;
  height: 90%;
  border-radius: 10px;
  display: flex;
  overflow: hidden;  
}

div.mainPageContainer {
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.pageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

div.subContainer {
  width: 100%;
  height: 100%;
  padding: 40px;
}

.transp {
  background-color: rgba(255, 255, 255, 0.5) !important;
}


div.chooseRoomDiv {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.chooseRoomDiv > h2 {
  text-align: center;
}

div.chooseRoomDiv > * {
  margin-top: 20px;
}


.localVideoContainer {
    position: absolute;
    top: 100%;
    right: 10px;
    border-radius: 10px;
    width: 30%;
    min-width: 150px;
    overflow: hidden;
    box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.45);
    resize: both;
    direction: rtl;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 50;
    cursor: move;
}

.vid {
    display: block;
    width: 100%;
    height: 100%;
}

.videoChatStreamContainer {
    width: 100%;
    position: relative;
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.45);
    resize: both;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 50;    
    max-height: 100%;
}

.audioChatStreamContainer {
    position: relative;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
}

.videoChatStreamContainer > .videoChatStreamControls {
    opacity: 0;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 0.5s;
}

.videoChatStreamContainer:hover > .videoChatStreamControls {
    opacity: 1;
}

.videoChatStreamContainer > .videoChatStreamControls > i {
    color: rgba(255, 255, 255, 1);
    transform: rotate(90deg);
}

.videosContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 45%;
    width: 100%;
}

.videoChatRow {
    width: 100%;
    flex: 1;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
} 

.videoChatUsername {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 10px 20px;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.videoChatUsername.audio {
    padding: 5px;
}

div.userPrefsContainer {
    width: 100%;
    height: 100%;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
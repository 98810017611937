
div.loginContainer {
    width: 30%;
    min-width: 400px;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(1px);    
}

form.loginForm {
    padding: 10px 30px;
}

div.chatMessages {
    overflow-y: auto !important;
}

div.responseDiv {
    min-height: fit-content !important;
}

div.loginButton > button {
    width: 100% !important;
}
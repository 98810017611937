

div.roomItemDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: all 0.5s ease !important; 
    padding: 10px;
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;    
}

div.roomItemDiv:hover {
    background-color: rgba(50, 50, 50, 0.3);
}

div.roomItemDiv[active="true"] {
    background-color: rgba(80, 80, 80, 0.3);
}

div.roomItemDiv.currentVC {
    animation: activeVC 3s infinite linear !important;
}

@keyframes activeVC {
    0% {
        background-color: rgba(0, 180, 0, 0.5);
        box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.25) inset;
    }
    50% {
        background-color: rgba(0, 180, 0, 0.3);
        box-shadow: none;
    }
    100% {
        background-color: rgba(0, 180, 0, 0.5);
        box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.25) inset;
    }
}

div.roomIconDiv {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid black;
    box-shadow: 1px 1px 4px 0px rgba(10, 10, 10, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.roomIconText {
    font-size: 1rem;
}

div.roomItemInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

div.roomItemInfoHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

div.roomItemInfoHeader > .roomItemName {
    white-space: nowrap;
    margin-right: 10px !important;
}

div.roomItemInfoHeader > .roomTimestamp {
    font-size: 0.8rem;
    color: rgba(0,0,0,.5);
    white-space: nowrap;
}

div.roomItemInfo > .roomLastMessage {
    font-size: 1rem;
    color: rgba(0,0,0,.7);
    white-space: nowrap;
    margin-right: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;    
}

div.roomItemOptions {
    height: 0px;
    min-height: 0px;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.5s;
    opacity: 0;
}

div.roomItemOptions.open {
    margin: 5px 0;
    height: 22px;
    max-height: 22px;
    min-height: 22px;
    opacity: 1;
}

div.roomItemOptions i {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
}

div.roomItemOptions i:hover {
    color: rgba(50, 150, 200, 0.5)
}

div.roomItemOptions i.fa-circle-xmark:hover,
div.roomItemOptions i.fa-trash:hover {
    color: rgba(255, 0, 0, 0.5)
}

.roomIconDiv > .notificationIcon {
    position: absolute;
    min-width: 18px;
    height: 18px;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 3px;
    font-size: 12px;
    line-height: 12px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.65) inset;
}

.notificationIcon.unreadIcon {
    top: -6px;
    right: -6px;
    background-color: red;
    color: white;
}

.notificationIcon.hangupIcon {
    color: black;
    width: 40px;
    height: 20px;
    bottom: -10px;
    font-size: 15px;
    background-color: red;
    cursor: pointer;
}

.roomIconDiv > .notificationIcon.videoChatUserCount {
    top: -6px;
    left: -10px;
    color: white;
    background-color: rgba(50, 150, 200, 1);
    min-width: 28px;
}

.roomIconDiv > .notificationIcon.videoChatUserCount > i {
    font-size: 8px;
}


div.tenorGifSearchContainer.open {
    max-height: 400px;
    min-height: 400px;
    opacity: 1;
}

.tenorGifSearchContainer {
    width: 100%;
    max-height: 0px;
    min-height: 0px;
    opacity: 0;
    padding: 0 10px;
    display: flex;
    /* background-color: rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    flex-direction: column;
    transition: all 0.8s ease;
}

.gifSearchMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.gifSearchMenu > i.circular.icon {
    width: 80px !important;
    height: 40px !important;
    font-size: 24px !important;
    line-height: 18px !important;
    transition: all 0.5s;
}

.gifSearchMenu > i.circular.icon:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.searchInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
}

.tenorGifSearchContainer input.gifSearchInput,
.tenorGifSearchContainer input.gifSearchInput:active,
.tenorGifSearchContainer input.gifSearchInput:focus {
    background-color: transparent;
    color: white;
    border: none;
    height: 100%;
    padding: 0 15px;
    width: 95%;
}

.tenorGifSearchContainer input.gifSearchInput:focus-visible {
    outline: none;
}

.tenorGifSearchContainer input.gifSearchInput::placeholder {
    color: rgb(200, 200, 200) !important;
}

.tenorGifSearchContainer input.gifSearchInput ~ i.close.circular.icon {
    color: black;
    margin-right: 10px;
}

.gifSetContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    margin: 10px 0;
}

.gifSet {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.gifContainer {
    margin: 10px 0;
    width: min-content;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

button.loadMoreButton {
    width: 90%;
    height: 30px;
    min-height: 30px;
    border-radius: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.5s;
    font-weight: 600;
    color: rgb(50, 50, 50);
}

button.loadMoreButton:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

div.chatUpperToolbar {
    height: 45px;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    box-shadow: -3px -3px 5px 0px rgb(0 0 0 / 30%) inset;
}

div.chatMessages {
    font-size: 1rem;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 10px;
    width: 100%;
}

div.chatMessages.animate {
    animation: fadein 0.5s;
}

div.responseDiv {
    flex-shrink: 0;
    flex-basis: auto;
    border-top: 2px solid rgba(200, 200, 200, .5) !important;
    padding: 1em;
}

div.textAreaContainer {
    position: 'relative';
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.textAreaIcons {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto 10px;
    height: 100%;
    max-height: 42px;
    display: flex;
    align-items: center;  
}

div.textAreaIcons > i.icon {
    margin: 0 0 0 10px;
    transition: all 0.3s linear;
}

div.textAreaIcons > i.icon:not(.paper.plane) {
    color: rgb(120, 120, 120);
}

div.textAreaIcons > i.icon:not(.paper.plane):hover {
    color: rgb(0, 0, 0);
    transform: scale(1.1);
}

div.textAreaIcons > i.icon.paper.plane:hover {
    color: rgb(50, 150, 200);
    transform: scale(1.1);
}

button.headerMenuActionButton {
    width: 100% !important;
}

.chatMessages .comment:first-child {
    margin-top: 0;
    padding-top: 0;
}

.chatMessages .comment {
    position: relative;
    background: 0 0;
    margin: 0.5em 0 0;
    padding: 0.5em 0 0;
    border: none;
    border-top: none;
    line-height: 1.2;
}

/* Right side menu stuff */

.textAreaContainer > .rightMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rightMenu > .rightMenuContentDiv {
    max-width: 0px;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
}

.rightMenu > .rightMenuContentDiv.open {
    max-width: 100px;
    max-height: none;
    margin: 0 5px;
}

.rightMenuContentDiv .rightMenuContentIconDiv {
    margin: 5px;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
}

.rightMenuContentDiv .rightMenuContentIconDiv:hover {
    color: rgba(0, 0, 0, 0.9);
}

.rightMenu i:hover {
    transform: scale(1.2);
}

.rightMenu i {
    transition: all 0.5s;
}

div.sendIconDiv {
    width: 50px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
}

div.sendIconDiv > i {
    font-size: 28px;
}

div.sendIconDiv > i:hover {
    color: rgb(50, 150, 200);
}


.openVideoControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    max-width: 400px;
    padding: 0 10px;
}

.controlButtonIcon {
    font-size: 18px;
    color: white;
    height: 34px;
    min-width: 34px;
    padding: 0 5px;
    line-height: 34px;
    text-align: center;  
    border-radius: 17px;
    background-color: rgba(50, 150, 200);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6) inset;   
    cursor: pointer; 
    transition: all 0.5s;
}

.controlButtonIcon:hover {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
}

.openVideoControls > .controlButtonIcon.buttonOn {
    background-color: red;
}

.openVideoControls > .controlButtonIcon.endCallButton {
    background-color: red;
    width: 48px;
}

.closedVideoControls {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.controlButtonIcon > * {
    padding: 0 5px;
}

.controlButtonIcon.audioCall {
    margin-right: 5px;
}



div.createRoomContainer {
    width: 100%;
    height: 100%;
    padding: 0 20px;
}

div.radioDiv {
    margin-top: 10px;
    margin-bottom: 20px;
}

label.formLabel {
    font-size: 1.2rem;
}

div.radioDiv > div.field {
    margin: 0 !important;
}

div.createButtonDiv {
    width: 100%;
    margin-top: 20px;
}

div.createButtonDiv button {
    width: 100%;
}
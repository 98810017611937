div.formFieldContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
}

div.formFieldContainer.labelLeft {
    flex-direction: row;
}

div.formFieldContainer.labelRight {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

h4.formFieldLabel {
    font-size: 1.2rem;
    align-self: flex-start;
}

div.formFieldInputDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    position: relative;
}

div.formFieldInputDiv:not(.checkbox) {
    width: 100%;
}

div.formFieldInputDiv.bottom {
    flex-direction: column;
    align-items: flex-start;
}

div.formFieldInputDiv > * {
    margin-bottom: 3px;
}

input.formFieldInput {
    flex: 4;
    padding: 10px;
    width: 100%;
    min-width: 200px;
    border-radius: 5px;
    border: 1px solid rgb(150, 150, 150);
}

input.formFieldInput[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    min-width: min-content;
}

input.formFieldInput.attached {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

input.formFieldInput.bottom {
    width: 100%;
}

button.formFieldInputButton {
    flex: 1;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid rgb(150, 150, 150);
    background-color: rgb(50, 150, 250);
    font-weight: 600;
    letter-spacing: 0px;
    color: white;
    transition: all 0.3s ease;
}

button.formFieldInputButton.right {
    margin-left: 5px;
    margin-right: 5px;
}

button.formFieldInputButton.attached {
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

button.formFieldInputButton.bottom {
    width: 100%;
}

button.formFieldInputButton:hover {
    background-color: rgb(200, 200, 200);
} 